import type { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import API from 'af-constants/routes/api';
import { COMPANY_CREATE, COMPANY_EDIT } from 'af-constants/reduxForms';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type { CompanyRequestModel, CompanyWorkDaysForm, CompanyPrintoutRM, EHSRM, EnableWorkRequestsRM, UpdatePurchaseOrderNumberPrefixRM, EnableInvoiceNotificationsRM } from 'ab-requestModels/company.requestModel';
import { ScheduleBoardSettingsForm } from 'ab-requestModels/scheduleBoard.requestModel';
import { WorkOrderSettingsRM } from 'ab-requestModels/company/workOrderSettings.requestModel';
import { FieldReportSettingsRM } from 'ab-requestModels/company/fieldReportSettings.requestModel';
import type { GeneralNotifications, SpecialEmails, TemporaryLaborNotificationsRM } from 'ab-requestModels/notificationSettings.requestModel';
import { DefaultNotificationTimes, AutomaticNotifications } from 'ab-requestModels/notificationSettings.requestModel';
import type { EquipmentSettingsRM } from 'ab-requestModels/equipment.requestModel';
import type { LaborSettingsRM } from 'ab-requestModels/employee.requestModel';

import type { CompanyAfterCreationViewModel, CompanyViewModel } from 'ab-viewModels/company.viewModel';
import type { MemberViewModel } from 'ab-viewModels/member.viewModel';
import type { OrganizationMemberVM } from 'ab-viewModels/organization/member.viewModel';
import type { NotificationSettingsViewModel } from 'ab-viewModels/notification.viewModel';
import type UserVM from 'ab-viewModels/user/purchaseOrderOption.viewModel';

import * as FormUtil from 'ab-utils/form.util';
import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler, defaultRedirectUrl } from 'af-utils/actions.util';

import { _refreshUser } from 'af-actions/authentication/authentication.actions';
import * as companyActionCreators from 'af-actions/companies/companies.actionCreators';
import * as authenticationActionCreators from 'af-actions/authentication/authentication.actionCreators';
import type * as generalActionCreators from 'af-actions/general/general.actionCreators';

import type { GetRootState } from 'af-reducers';

import * as EditPortalFunctionalitiesAPI from 'ab-api/web/company/editPortalFunctionalities';

// Public Functions

export function createCompany(form: CompanyRequestModel, orgAlias: string) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.AuthenticationAction | generalActionCreators.GeneralAction>,
		getState: GetRootState,
		{ redirectTo }
	) => {

		const action = async () => {
			const options = {
				submitting: COMPANY_CREATE,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'image');

			const { user, company } = await http.post<CompanyAfterCreationViewModel>(API.V1.COMPANY.CREATE, fd, options);

			dispatch(companyActionCreators.GET_COMPANY(company));
			const redirectUrl = defaultRedirectUrl(orgAlias, company.name, [], true, user.user.role);
			_refreshUser(user, company.name, dispatch, redirectTo, redirectUrl);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteUser(userId: number) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.delete(API.V1.USERS.DELETE(userId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editCompany(form: CompanyRequestModel) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.UpdateCurrentCompany>,
		getState: GetRootState,
		{ redirectTo }
	) => {

		const action = async () => {
			const options = {
				submitting: COMPANY_EDIT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'image');

			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT, fd, options);
			dispatch(companyActionCreators.GET_COMPANY(response));
			dispatch(authenticationActionCreators.UPDATE_CURRENT_COMPANY(response));
		};

		const error: ErrorOverride = {
			err400: () => {
				throw new SubmissionError({ _error: 'Company name is already in use.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function editPrintoutHeader(form: CompanyPrintoutRM) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.UpdateCurrentCompany>,
		getState: GetRootState, { redirectTo }
	) => {

		const action = async () => {
			const options = {
				submitting: COMPANY_EDIT,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, 'customPDFHeader');

			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_PRINTOUT, fd, options);
			dispatch(companyActionCreators.GET_COMPANY(response));
			dispatch(authenticationActionCreators.UPDATE_CURRENT_COMPANY(response));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editGeneralNotifications(form: GeneralNotifications) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const notificationSettings = await http.put<GeneralNotifications>(API.V1.NOTIFY.EDIT_GENERAL, form);
			dispatch(companyActionCreators.GET_COMPANY_NOTIFICATION_SETTINGS(notificationSettings));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editDefaultNotificationSendTimes(form: DefaultNotificationTimes) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const notificationSettings = await http.put<DefaultNotificationTimes>(API.V1.NOTIFY.EDIT_DEFAULT_TIMES, form);
			dispatch(companyActionCreators.GET_COMPANY_NOTIFICATION_SETTINGS(DefaultNotificationTimes.toFrom(notificationSettings)));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editSpecialEmailNotifications(form: SpecialEmails) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const notificationSettings = await http.put<SpecialEmails>(API.V1.NOTIFY.EDIT_SPECIAL_EMAILS, form);
			dispatch(companyActionCreators.GET_COMPANY_NOTIFICATION_SETTINGS(notificationSettings as Partial<NotificationSettingsViewModel>));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editAutomaticNotifications(form: AutomaticNotifications) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const requestForm: AutomaticNotifications = AutomaticNotifications.fromForm(form);
			const notificationSettings = await http.put<AutomaticNotifications>(API.V1.NOTIFY.EDIT_AUTOMATIC, requestForm);
			dispatch(companyActionCreators.GET_COMPANY_NOTIFICATION_SETTINGS(notificationSettings));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editTemporaryLaborNotifications(form: TemporaryLaborNotificationsRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const notificationSettings = await http.put<NotificationSettingsViewModel>(API.V1.NOTIFY.EDIT_TEMPORARY_LABOR, form);
			dispatch(companyActionCreators.GET_COMPANY_NOTIFICATION_SETTINGS(notificationSettings));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editCompanyWorkDays(form: CompanyWorkDaysForm) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const response: CompanyViewModel = await http.put(API.V1.COMPANY.EDIT_WORK_DAYS, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
		};
		const error: ErrorOverride = {
			err400: () => {
				throw new SubmissionError({ _error: 'Company name is already in use.' });
			},
		};
		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function editScheduleBoardSettings(form: ScheduleBoardSettingsForm) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_SCHEDULE_BOARD_SETTINGS, ScheduleBoardSettingsForm.toModel(form));
			dispatch(companyActionCreators.GET_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editWorkOrderSettings(form: WorkOrderSettingsRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_WORK_ORDER_SETTINGS, WorkOrderSettingsRM.toModel(form));
			dispatch(companyActionCreators.GET_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editFieldReportSettings(form: FieldReportSettingsRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_FIELD_REPORT_SETTINGS, FieldReportSettingsRM.toModel(form));
			dispatch(companyActionCreators.GET_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getCompany() {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const company = await http.get<CompanyViewModel>(API.V1.COMPANY.FIND_BY_ID);
			dispatch(companyActionCreators.GET_COMPANY(company));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getAllOrganizationMembers() {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<OrganizationMemberVM[]>(API.V1.ORGANIZATION.MEMBERS_LIST());
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getMembersForOrganizationByAlias(tableRequestModel: TableQuery = {} as TableQuery, alias: string) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<MemberViewModel>>(API.V1.ORGANIZATION.USERS.LIST(data, alias));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function removeOrganizationAdminPrivileges(userId: string, alias: string) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.ORGANIZATION.USERS.UPDATE_ADMIN_PRIVILEGES(userId, alias, false));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function giveOrganizationAdminPrivileges(userId: string, alias: string) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(API.V1.ORGANIZATION.USERS.UPDATE_ADMIN_PRIVILEGES(userId, alias, true));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getMembersForOrganization(tableRequestModel: TableQuery = {} as TableQuery) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const fullName = tableRequestModel.sortBy?.find((s) => s.id === 'fullName');
			if (fullName) {
				tableRequestModel.sortBy = (tableRequestModel.sortBy ?? []).filter((_s) => _s.id !== 'fullName').concat(
					{ id: 'lastName', desc: fullName.desc },
					{ id: 'firstName', desc: fullName.desc }
				);
			}

			const data = new TableQuery(tableRequestModel);
			return await http.get<TableContent<OrganizationMemberVM>>(API.V1.ORGANIZATION.MEMBERS_TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editEquipmentSettings(form: EquipmentSettingsRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.patch<CompanyViewModel>(API.V1.COMPANY.EDIT_EQUIPMENT_SETTINGS, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editLaborSettings(form: LaborSettingsRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.patch<CompanyViewModel>(API.V1.COMPANY.EDIT_LABOR_SETTINGS, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editEHS(form: EHSRM) {
	return async (dispatch: Dispatch<companyActionCreators.CompanyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_EHS, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editEnableWorkRequests(form: EnableWorkRequestsRM) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.UpdateCurrentCompany>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_ENABLE_WORK_REQUESTS, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
			dispatch(authenticationActionCreators.UPDATE_CURRENT_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editEnablePortalFunctionalities(form: EditPortalFunctionalitiesAPI.W_Company_EditPortalFunctionalities_RM) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.UpdateCurrentCompany>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {
			const response = await http.put<EditPortalFunctionalitiesAPI.W_Company_EditPortalFunctionalities_VM>(
				EditPortalFunctionalitiesAPI.URL(),
				form
			);
			dispatch(companyActionCreators.GET_COMPANY(response));
			dispatch(authenticationActionCreators.UPDATE_CURRENT_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editEnableInvoiceNotifications(form: EnableInvoiceNotificationsRM) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.UpdateCurrentCompany>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.EDIT_ENABLE_INVOICE_NOTIFICATIONS, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
			dispatch(authenticationActionCreators.UPDATE_CURRENT_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updatePurchaseOrderNumberPrefix(form: UpdatePurchaseOrderNumberPrefixRM) {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction | authenticationActionCreators.UpdateCurrentCompany>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {
			const response = await http.put<CompanyViewModel>(API.V1.COMPANY.UPDATE_PURCHASE_ORDER_NUMBER_PREFIX, form);
			dispatch(companyActionCreators.GET_COMPANY(response));
			dispatch(authenticationActionCreators.UPDATE_CURRENT_COMPANY(response));
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findCompanyUsers() {
	return async (
		dispatch: Dispatch<companyActionCreators.CompanyAction>,
		getState: GetRootState, { redirectTo }
	) => {
		const action = async () => {
			const response = await http.get<UserVM[]>(API.V1.COMPANY.FIND_USERS);
			return response;
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}
