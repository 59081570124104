import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ResolveThunks } from 'react-redux';
import { connect } from 'react-redux';

import type UserPermission from 'acceligent-shared/enums/userPermission';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import MemberInviteEnum from 'acceligent-shared/enums/memberInvite';

import type { RootState } from 'af-reducers';

import * as CompanyActions from 'af-actions/companies';

import type { OrganizationMemberVM } from 'ab-viewModels/organization/member.viewModel';

import { DEFAULT_EMPLOYEE_IMAGE } from 'ab-common/constants/value';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import DateCell from 'af-components/Table6/Cells/DateCell';
import type { TabProps, Column } from 'af-components/Table6';
import Table from 'af-components/Table6';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import ImageTag from 'af-components/Image';
import Breadcrumbs from 'af-components/Breadcrumbs';
import StatusCell from 'af-components/Table6/Cells/StatusCell';

import { formatPhoneNumber } from 'ab-utils/phone.util';

import { defaultRedirectUrl } from 'af-utils/actions.util';
import { downloadCSV } from 'af-utils/csv.utils';

type OwnProps = CustomRouteComponentProps;

interface StateProps {
	userRole: UserPermission;
	organizationName: string;
	isPlatformAdmin: boolean;
}

interface DispatchProps {
	getAllOrganizationMembers: typeof CompanyActions.getAllOrganizationMembers;
	deleteUser: typeof CompanyActions.deleteUser;
	getMembersForOrganization: typeof CompanyActions.getMembersForOrganization;
}

type Props = ResolveThunks<DispatchProps> & StateProps & OwnProps;

class OrganizationMembers extends React.Component<Props> {
	columns: Column<OrganizationMemberVM>[] = [
		{
			accessor: 'imageUrl',
			sortable: false,
			width: 100,
			className: 'rt-thumbnail',
			Cell: ({ original }) => (
				<ImageTag
					fallbackSrc={DEFAULT_EMPLOYEE_IMAGE}
					minSize={BlobStorageImageSizeContainer.SIZE_50X50}
					src={original.imageUrl}
					tryOriginal={true}
					tryRoot={true}
				/>
			),
		},
		{
			Header: 'Unique ID',
			accessor: 'uniqueId',
			className: 'text-strong',
			Cell: ({ original }) => original.uniqueId,
		},
		{
			Header: 'Full Name',
			accessor: 'fullName',
			className: 'text-strong',
			Cell: ({ original }) => original.fullName,
		},
		{
			Header: 'Email Address',
			accessor: 'email',
			Cell: ({ original }) => original.email ?? <EmptyCell />,
		},
		{
			Header: 'Mobile Phone',
			accessor: 'phoneNumber',
			Cell: ({ original }) => original.phoneNumber
				? formatPhoneNumber(original.phoneNumber, original.countryCode)
				: <EmptyCell />,
		},
		{
			Header: 'Last Login',
			accessor: 'lastLoginAt',
			Cell: ({ original }) => <DateCell date={original.lastLoginAt} format={TimeFormatEnum.DATE_ONLY} isLeftAligned={true} withTime={true} />,
		},
		{
			Header: 'Status',
			accessor: 'status',
			filterable: false,
			Cell: ({ original }) => <StatusCell {...original}/>,
		},
		{
			Header: 'LMS access',
			accessor: 'activeInLMS',
			filterable: false,
			Cell: ({ original }) => {
				if (original.activeInLMS) {
					return (<span className="text-green">Yes</span>);
				}
				return (<span className="text-grey">No</span>);
			},
		},
		{
			accessor: 'companies',
			Header: 'Companies',
			Cell: ({ original }) => {
				const { userRole, isPlatformAdmin, location: { state: { orgAlias } } } = this.props;
				return original.companies.map((_company: string, _index: number) => {
					const redirectUrl = defaultRedirectUrl(orgAlias, _company, [], isPlatformAdmin, userRole);
					return (
						<div
							key={_index}
							title={_company}
						>
							<Link to={redirectUrl}>
								{_company}
							</Link>
						</div>
					);
				});
			},
		},
	];

	onDownloadCSVClick = async () => {
		const { getAllOrganizationMembers, organizationName } = this.props;
		const members = await getAllOrganizationMembers();
		const data = members.map((_member) => [
			_member.uniqueId,
			_member.fullName,
			_member.email ?? '',
			_member.phoneNumber ?? '',
			_member.role,
			_member.status,
		]);
		data.unshift([
			'uniqueId',
			'fullName',
			'email',
			'phoneNumber',
			'role',
			'status',
		]);
		downloadCSV(data, `${organizationName}_users.csv`);
	};

	deleteUser = async (original: OrganizationMemberVM) => {
		const { deleteUser } = this.props;

		await deleteUser(original.userId);
	};

	deleteUserModalTitle = (original: OrganizationMemberVM) => (`Are you sure you want to delete this User for organization (${original.fullName})?`);
	deleteUserModalBody = () => (<>This action cannot be undone. </>);
	deleteUserModalText = () => ('Delete User');

	tabs = (): TabProps<OrganizationMemberVM>[] => {
		const { getMembersForOrganization } = this.props;

		return [
			{
				label: 'All Users',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Members',
				buttons: [{
					type: TableButtonType.EXPORT,
					hasPermission: true,
					onClick: this.onDownloadCSVClick,
				}],
				fetch: getMembersForOrganization,
				rowActions: [
					{
						label: 'Deactivate for Organization',
						action: this.deleteUser,
						hasModal: true,
						modalTitle: this.deleteUserModalTitle,
						modalBody: this.deleteUserModalBody,
						modalText: this.deleteUserModalText,
						shouldRefresh: true,
						hide: (original: OrganizationMemberVM) => original.status === MemberInviteEnum.DELETED,
					},
				],
			},
		];
	};

	breadcrumbs = () => [{ label: 'Users' }];

	render() {
		return (
			<div className="form-segment form-segment--maxi">
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					tableName={TableNameEnum.ORGANIZATION_MEMBERS}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState): StateProps {
	const { userData, organizationData } = state.user;
	if (!userData || !organizationData) {
		throw new Error('User not logged in');
	}

	return {
		userRole: userData.role,
		organizationName: organizationData.name,
		isPlatformAdmin: organizationData.isPlatformAdmin,
	};
}

function mapDispatchToProps(): DispatchProps {
	return {
		getAllOrganizationMembers: CompanyActions.getAllOrganizationMembers,
		deleteUser: CompanyActions.deleteUser,
		getMembersForOrganization: CompanyActions.getMembersForOrganization,
	};
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps())(OrganizationMembers);
