import type { ColorPalette } from 'acceligent-shared/enums/color';
import type WorkOrderReviewStatus from 'acceligent-shared/enums/workOrderReviewStatus';
import type { TimeSheetInternalApprovalStatus } from 'acceligent-shared/enums/timeSheetApprovalStatus';
import type TimeSheetEntryWorkType from 'acceligent-shared/enums/timeSheetEntryWorkType';
import type TimeSheetSignatureStatus from 'acceligent-shared/enums/timeSheetSignatureStatus';
import type WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import type { CalculatedReportDisplayStatus } from 'acceligent-shared/enums/reportDisplayStatus';
import type TimeSheetEntryType from 'acceligent-shared/enums/timeSheetEntryType';

import * as TimeSheetUtils from 'acceligent-shared/utils/timeSheet';
import * as TimeUtils from 'acceligent-shared/utils/time';

import { DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL } from 'acceligent-shared/constants/value';

import * as BlobStorageUtil from 'ab-utils/blobStorage.util';

import type FieldReportCondensedWorkOrderBase from 'ab-domain/views/fieldReportCondensedWorkOrder/base';

export class W_WorkOrderReport_CondensedViewSingle_VM {
	id: number;
	dueDate: string;
	workOrderCode: string;
	reviewLevel: number;
	reviewStatus: WorkOrderReviewStatus;
	calculatedReportDisplayStatus: CalculatedReportDisplayStatus;
	lastApprovedAt: Nullable<string>;
	lastApprovedById: Nullable<number>;
	lastApprovedBy: Nullable<string>;
	assignedToIds: number[];
	location: W_WorkOrderReport_CondensedViewSingle_VM_Location;
	employees: W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM[];
	revision: number;
	revisionCreatedAt: Date;
	woUpdatedAt: Date;
	status: WorkOrderStatus;
	workRequestId: number;
	isPartOfProject: boolean;

	constructor(row: FieldReportCondensedWorkOrderBase) {
		this.id = row.id;
		this.dueDate = row.dueDate;
		this.workOrderCode = row.workOrderCode;
		this.reviewLevel = row.reviewLevel;
		this.reviewStatus = row.reviewStatus;
		this.calculatedReportDisplayStatus = row.calculatedReportDisplayStatus;
		this.lastApprovedAt = row.lastApprovedAt;
		this.lastApprovedById = row.lastApprovedById;
		this.lastApprovedBy = row.lastApprovedBy;
		this.assignedToIds = row.assignedToIds;
		this.location = new W_WorkOrderReport_CondensedViewSingle_VM_Location(row);
		this.employees = row.employees ? W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM.bulkConstructor(row.employees) : [];
		this.revision = row.revision;
		this.revisionCreatedAt = row.revisionCreatedAt;
		this.woUpdatedAt = row.woUpdatedAt;
		this.status = row.status;
		this.workRequestId = row.workRequestId;
		this.isPartOfProject = row.isPartOfProject;
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_Location {
	aa1: string;
	street: string;

	constructor(row: FieldReportCondensedWorkOrderBase) {
		this.aa1 = row.aa1;
		this.street = row.street;
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry {
	id: number;
	workType: TimeSheetEntryWorkType;
	/** ISO_DATETIME */
	startTime: string;
	/** ISO_DATETIME */
	endTime: Nullable<string>;
	isInActiveShift: boolean;
	type: TimeSheetEntryType;
	equipmentId: Nullable<number>;

	constructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSheetEntries'][0]) {
		this.id = entry.id;
		this.workType = entry.workType;
		this.startTime = entry.startTime;
		this.endTime = entry.endTime;
		this.isInActiveShift = entry.isInActiveShift;
		this.type = entry.type;
		this.equipmentId = entry.equipmentId;
	}

	static bulkConstructor(entries: FieldReportCondensedWorkOrderBase['employees'][0]['timeSheetEntries']) {
		return entries?.map?.(W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry._mapConstructor) ?? [];
	}

	private static _mapConstructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSheetEntries'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry(entry);
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry {
	id: number;
	time: number;
	fieldWorkClassificationCodeId: number;
	classificationCode: string;
	equipmentId: number;
	equipmentCode: string;
	equipmentSpecification: string;
	color: ColorPalette;
	allocatedWorkRequestId: number;

	constructor(timeSplitEntry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSplitEntries'][0]) {
		this.id = timeSplitEntry.id;
		this.time = TimeUtils.roundTimeDurationToInterval(timeSplitEntry.time, DEFAULT_TIME_DURATION_MINUTE_ROUNDING_INTERVAL);
		this.fieldWorkClassificationCodeId = timeSplitEntry.fieldWorkClassificationCodeId;
		this.classificationCode = timeSplitEntry.classificationCode;
		this.equipmentId = timeSplitEntry.equipmentId;
		this.equipmentCode = timeSplitEntry.equipmentCode;
		this.equipmentSpecification = timeSplitEntry.equipmentSpecification;
		this.color = timeSplitEntry.color;
		this.allocatedWorkRequestId = timeSplitEntry.allocatedWorkRequestId;
	}

	static bulkConstructor(entries: FieldReportCondensedWorkOrderBase['employees'][0]['timeSplitEntries']) {
		return entries?.map?.(W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry._mapConstructor) ?? [];
	}

	private static _mapConstructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeSplitEntries'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry(entry);
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry {
	id: number;
	time: number;
	workType: TimeSheetEntryWorkType;
	allocatedWorkRequestId: number;

	constructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeAllocationEntries'][0]) {
		this.id = entry.id;
		this.time = entry.time;
		this.workType = entry.workType;
		this.allocatedWorkRequestId = entry.allocatedWorkRequestId;
	}

	static bulkConstructor(entries: FieldReportCondensedWorkOrderBase['employees'][0]['timeAllocationEntries']) {
		return entries?.map?.(W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry._mapConstructor) ?? [];
	}

	private static _mapConstructor(entry: FieldReportCondensedWorkOrderBase['employees'][0]['timeAllocationEntries'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry(entry);
	}
}

class W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM {
	accountId: number;
	fullName: string;
	isTemporaryEmployee: boolean;

	timeSheetOutdated: boolean;
	signatureStatus: TimeSheetSignatureStatus;

	approvalStatus: TimeSheetInternalApprovalStatus;
	approvedBy: Nullable<string>;
	approvedAt: Nullable<string>;
	approvalSignatureUrl: Nullable<string>;

	rejectedBy: Nullable<string>;
	rejectedAt: Nullable<string>;
	rejectReason: Nullable<string>;

	timeSheetId: number;
	timeSheetEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry[];

	timeSplitId: number;
	timeSplitEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry[];

	timeAllocationEntries: W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry[];

	constructor(row: FieldReportCondensedWorkOrderBase['employees'][0]) {
		this.accountId = row.accountId;
		this.fullName = row.fullName;
		this.isTemporaryEmployee = row.isTemporaryEmployee;
		this.timeSheetOutdated = row.timeSheetOutdated;
		this.signatureStatus = TimeSheetUtils.resolveTimeSheetSignatureStatus({
			approvalStatus: row.approvalStatus,
			parentId: row.parentId,
			signatureId: row.signatureId,
		});
		this.approvalStatus = row.approvalStatus;
		this.approvedBy = row.approvedBy;
		this.approvedAt = row.approvedAt;
		this.approvalSignatureUrl = row.approvalSignatureStorageContainer && row.approvalSignatureStorageName
			? BlobStorageUtil.generatePresignedGetUrl(row.approvalSignatureStorageContainer, row.approvalSignatureStorageName)
			: null;
		this.rejectedBy = row.rejectedBy;
		this.rejectedAt = row.rejectedAt;
		this.rejectReason = row.rejectReason;
		this.timeSheetId = row.timeSheetId;
		this.timeSheetEntries = W_WorkOrderReport_CondensedViewSingle_VM_TimeSheetEntry.bulkConstructor(row.timeSheetEntries);
		this.timeSplitId = row.timeSplitId;
		this.timeSplitEntries = W_WorkOrderReport_CondensedViewSingle_VM_TimeSplitEntry.bulkConstructor(row.timeSplitEntries);
		this.timeAllocationEntries = W_WorkOrderReport_CondensedViewSingle_VM_TimeAllocationEntry.bulkConstructor(row.timeAllocationEntries);
	}

	static bulkConstructor(rows: FieldReportCondensedWorkOrderBase['employees']) {
		return rows.map(W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM._mapConstructor);
	}

	private static _mapConstructor(row: FieldReportCondensedWorkOrderBase['employees'][0]) {
		return new W_WorkOrderReport_CondensedViewSingle_VM_EmployeeVM(row);
	}
}

