import type { AnyAction, Dispatch } from 'redux';

import type { TableContent } from 'ab-common/dataStructures/tableContent';

import type OrderTableVM from 'ab-viewModels/order/orderTable.viewModel';
import type OrderUpsertVM from 'ab-viewModels/order/orderUpsert.viewModel';

import type { GetRootState } from 'af-reducers';

import { http } from 'af-utils/http.util';
import { errorHandler } from 'af-utils/actions.util';

import API from 'af-routes/api';

import type { TableQuery } from 'ab-common/dataStructures/tableQuery';

import type OrderUpsertRM from 'ab-requestModels/order/upsert.requestModel';

export function findForEdit(orderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<OrderUpsertVM>(API.V1.ORDERS.FIND_FOR_EDIT(orderId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

/**
 *
 * @param tableQuery
 * @param startDate YYYY-MM-DD
 * @param endDate YYYY-MM-DD
 * @returns
 */
export function findForTable(tableQuery: TableQuery, startDate: string, endDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<TableContent<OrderTableVM>>(API.V1.ORDERS.FIND_FOR_TABLE(tableQuery, startDate, endDate));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createOrder(rm: OrderUpsertRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post(API.V1.ORDERS.CREATE, rm);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editOrder(id: number, rm: OrderUpsertRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.put(API.V1.ORDERS.EDIT(id), rm);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteOrder(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.delete(API.V1.ORDERS.DELETE(id));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
