import * as React from 'react';

import { useScheduleBoardModals } from 'af-root/hooks/useScheduleBoardModal';

import { ScheduleBoardModalContextProvider } from 'af-root/context/scheduleBoardModalContext';

export default function withScheduleBoardModals<P extends object>(
	WrappedComponent: React.ComponentType<P>
): React.FC<P> {
	const WithScheduleBoardModal: React.FC<P> = (props: P) => {
		const scheduleBoardModals = useScheduleBoardModals();

		return (
			<ScheduleBoardModalContextProvider>
				<WrappedComponent scheduleBoardModals={scheduleBoardModals} {...props as P} />
			</ScheduleBoardModalContextProvider>
		);

	};
	return WithScheduleBoardModal;
}
