import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { CellContext, Row } from '@tanstack/react-table';

import OrderStatus from 'acceligent-shared/enums/orderStatus';
import type { TableContent } from 'ab-common/dataStructures/tableContent';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import * as TimeUtils from 'acceligent-shared/utils/time';

import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import ConfirmationModal from 'af-components/ConfirmationModal';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import TextCell from 'af-components/Table/Cells/TextCell';
import UpdatedByCell from 'af-components/Table/Cells/UpdatedByCell';

import CLIENT from 'af-routes/client';

import type { RootState } from 'af-reducers';

import * as OrderActions from 'af-actions/orders';

import type OrderTableVM from 'ab-viewModels/order/orderTable.viewModel';

import { isAllowed } from 'ab-utils/auth.util';
import * as SettingsUtils from 'af-utils/settings.util';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as SettingsKeys from 'af-constants/settingsKeys';

import styles from './styles.module.scss';
import OrderDeliveryMethod from 'acceligent-shared/enums/orderDeliveryMethod';
import DateFilter from 'af-components/DateFilter';
import Tooltip from 'af-components/Tooltip';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type Props = ConnectedProps<typeof connector> & SettingProps & CustomRouteComponentProps;

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormat.FULL_DATE);

};

const PAGINATION_KEYS = {
	pageNumberKey: SettingsKeys.ORDER_PAGE_NUMBER(),
	pageSizeKey: SettingsKeys.ORDER_PAGE_SIZE(),
};

const DELETE_ORDER_MODAL_BODY = (
	<>
		You are about to permanently delete this order.
		<br />
		Are you sure you want to proceed?
	</>
);

const mapDeliveryMethodToDisplay = (method: string) => {
	if (method === OrderDeliveryMethod.UNKNOWN) {
		return 'N/A';
	}
	return method;
};

const OrdersTable: React.FC<Props> = (props) => {
	const { findForTable, history, companyName, location: { state: { orgAlias } }, deleteOrder, canManage } = props;

	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState(false);
	const [orderToDelete, setOrderToDelete] = React.useState<Nullable<OrderTableVM>>(null);
	const tableRef = React.useRef<TableRef<OrderTableVM>>(null);

	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<OrderTableVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await findForTable(
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY),
			TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY)
		);
		return result;
	}, [endDate, findForTable, startDate]);

	const renderStatusCell = React.useCallback((cell: CellContext<OrderTableVM, string>) => {
		const status = cell.row.original.status;
		const items = cell.row.original.orderItems;

		let textCell = <TextCell className={styles['order-table__new-status']} value="New" />;

		switch (status) {

			case OrderStatus.COMPLETED: {
				textCell = <TextCell className={styles['order-table__completed-status']} value="Completed" />;
				break;
			}
			case OrderStatus.IN_PROGRESS: {
				textCell = <TextCell className={styles['order-table__in-progress-status']} value="In Progress" />;
				break;
			}
			default: {
				// Do nothing
			}
		}

		const tooltipMessage = items.length
			? items.map((i) => <>{`${i.name} | Quantity: ${i.quantity}`} <br /></>)
			: 'No items';

		return (
			<Tooltip
				message={tooltipMessage}
				placement="top"
			>
				{
					textCell
				}
			</Tooltip>);
	}, []);

	const redirectToEditOrderPage = React.useCallback((row: Row<OrderTableVM>) => {
		history.push(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${row.original.id}`));
	}, [companyName, history, orgAlias]);

	const confirmDeleteOrder = React.useCallback(async () => {
		if (!orderToDelete) {
			return;
		}
		await deleteOrder(orderToDelete.id);
		tableRef.current?.refreshTable();
	}, [deleteOrder, orderToDelete]);

	const onDeleteOrderClick = React.useCallback((row: Row<OrderTableVM>) => {
		setShowDeleteConfirmationModal(true);
		setOrderToDelete(row.original);
	}, []);

	const closeDeleteOrderConfirmationModal = React.useCallback(() => {
		setShowDeleteConfirmationModal(false);
		setOrderToDelete(null);
	}, []);

	const openPrintPreview = React.useCallback((orderId: number, initiatePrint: boolean, fullPrint?: boolean) => {
		const url = new URL(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${orderId}`), location.origin);
		url.searchParams.append('forPrint', 'true');
		if (initiatePrint) {
			url.searchParams.append('initiatePrint', 'true');

			if (fullPrint) {
				url.searchParams.append('fullPrint', 'true');
			}
		}

		window.open(url.toString(), '_blank');
	}, [companyName, orgAlias]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<OrderTableVM, unknown>) => {
		const options = [
			{ onClick: () => openPrintPreview(_cell.row.original.id, true, true), label: 'Print Full' },
			{ onClick: () => openPrintPreview(_cell.row.original.id, true), label: 'Print Condensed' },
			{ onClick: () => openPrintPreview(_cell.row.original.id, false), label: 'Preview' },
		];

		if (_cell.row.original.status !== OrderStatus.COMPLETED) {
			options.push({ onClick: () => redirectToEditOrderPage(_cell.row), label: 'Edit' });
		}

		if (canManage && _cell.row.original.status !== OrderStatus.COMPLETED) {
			options.push({
				onClick: async () => {
					await onDeleteOrderClick(_cell.row);
					tableRef.current?.refreshTable();
				}, label: 'Delete',
			});
		}

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={options}
				valueKey="label"
			/>
		);
	}, [canManage, redirectToEditOrderPage, openPrintPreview, onDeleteOrderClick]);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		tableRef.current?.resetPagination();
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_START_DATE(),
			_startDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_END_DATE(),
			_endDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
	}, []);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		tableRef.current?.resetPagination();
		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
		SettingsUtils.setItem(SettingsKeys.ORDER_PERIOD(), _period, BrowserStorageEnum.LOCAL_STORAGE);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_START_DATE(),
			_startDate, TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_END_DATE(),
			_endDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
	}, []);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<DateFilter
				changePeriod={changePeriod}
				endDate={endDate}
				onChange={filterByDate}
				period={period}
				startDate={startDate}
			/>
		);
	}, [changePeriod, endDate, filterByDate, period, startDate]);

	const columns: TableProps<OrderTableVM>['columns'] = React.useMemo(() => [
		{
			id: 'status',
			accessor: 'status',
			header: 'Status',
			cell: renderStatusCell,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'operator',
			accessor: 'operator',
			header: 'Operator',
			cell: (cell: CellContext<OrderTableVM, OrderTableVM['operator']>) => <TextCell value={cell.getValue()?.fullName ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'deliveryMethod',
			accessor: 'deliveryMethod',
			header: 'Delivery Method',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={mapDeliveryMethodToDisplay(cell.getValue())} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'orderType',
			accessor: 'orderType',
			header: 'Order Type',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue().replaceAll('_', ' ')} />,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'dateSubmitted',
			accessor: 'dateSubmitted',
			header: 'Date Submitted',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue() ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'dateNeeded',
			accessor: 'dateNeeded',
			header: 'Date Needed',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'location',
			accessor: 'locationNickname',
			header: 'Location',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue() ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'unfulfilled',
			accessor: 'unfulfilled',
			header: 'Unfulfilled',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'unassigned',
			accessor: 'unassigned',
			header: 'Unassigned',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'awaitingPickup',
			accessor: 'awaitingPickup',
			header: 'Awaiting Pickup',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'awaitingDelivery',
			accessor: 'awaitingDelivery',
			header: 'Awaiting Delivery',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'onOrder',
			accessor: 'onOrder',
			header: 'On Order',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'returned',
			accessor: 'returned',
			header: 'Returned',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'fulfilled',
			accessor: 'fulfilled',
			header: 'Fulfilled',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'createdAt',
			accessor: 'requestedAt',
			header: 'Requested',
			cell: (cell: CellContext<OrderTableVM, string>) =>
				<UpdatedByCell updatedAt={cell.row.original.requestedAt} updatedBy={cell.row.original.requestedBy} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'updatedAt',
			accessor: 'updatedAt',
			header: 'Updated',
			cell: (cell: CellContext<OrderTableVM, string>) =>
				<UpdatedByCell updatedAt={cell.row.original.updatedAt} updatedBy={cell.row.original.updatedBy} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderStatusCell, resolveActionsButton]);

	const redirectToCreateOrderPage = React.useCallback(async () => {
		history.push(CLIENT.COMPANY.ORDER.CREATE(orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const buttons = React.useMemo(() => [
		{ label: 'New Order', type: TableButtonType.PRIMARY, hasPermission: true, onClick: redirectToCreateOrderPage },
	], [redirectToCreateOrderPage]);

	return (
		<>
			<Breadcrumbs items={[{ label: 'Orders' }]} />
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				defaultPageSize={100}
				fetch={fetchRows}
				hasSearchInput={true}
				onRowClick={redirectToEditOrderPage}
				paginationKeys={PAGINATION_KEYS}
				ref={tableRef}
				searchTextKey={SettingsKeys.ORDER_SEARCH_TEXT()}
				tableName={TableNameEnum.ORDER}
			/>
			{(showDeleteConfirmationModal && orderToDelete) &&
				<ConfirmationModal
					body={DELETE_ORDER_MODAL_BODY}
					closeModal={closeDeleteOrderConfirmationModal}
					confirmAction={confirmDeleteOrder}
					confirmText="Delete"
					modalStyle="danger"
					showModal={showDeleteConfirmationModal}
					title="Delete Order"
				/>
			}
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData, userData } = state.user;
	if (!companyData || !userData) {
		throw new Error('User not logged in');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	return {
		companyName: companyData.name,
		canManage: isAllowed(PagePermissions.COMPANY.ORDER.MANAGE, permissions, isCompanyAdmin, role),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps());

function mapDispatchToProps() {
	return {
		findForTable: OrderActions.findForTable,
		deleteOrder: OrderActions.deleteOrder,
	};
}

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.ORDER_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.ORDER_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.ORDER_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(OrdersTable);
