import * as React from 'react';
import { Button } from 'react-bootstrap';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';

import { QuantityUnitLabel, QuantityUnitMap } from 'acceligent-shared/enums/quantityUnit';
import type QuantityUnitType from 'acceligent-shared/enums/quantityUnit';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import type { BillingCodeVM } from 'ab-viewModels/job.viewModel';
import type JobWorkSummaryVM from 'ab-viewModels/workRequest/jobWorkSummary.viewModel';
import type JobWorkSummaryWorkOrderVM from 'ab-viewModels/workRequest/jobWorkSummaryWorkOrder.viewModel';
import type { SubjobVM } from 'ab-viewModels/workRequest/jobUpsert.viewModel';

import type JobWorkSummaryRM from 'ab-requestModels/workRequest/jobWorkSummaryUpsert.requestModel';

import { UnitClasses } from 'ab-enums/unitConversion.enum';

import { convertUnits } from 'ab-utils/unitConversion.util';

import * as JobActions from 'af-actions/jobs';

import CustomModal from 'af-components/CustomModal';
import SubmitButton from 'af-components/SubmitButton';
import Dropdown from 'af-fields/Dropdown';
import DateInput from 'af-fields/DateInput';
import Textarea from 'af-fields/Textarea';

import * as FORMS from 'af-constants/reduxForms';

import type { RootState } from 'af-reducers';

import { dollarFormatter } from 'af-utils/format.util';

import Input from 'af-fields/Input';
import Text from 'af-fields/Text';

import AddBillableWorkModalInformationalField from './AddBillableWorkModalInformationalField';
import AddBillableWorkModalDefinitionField from './AddBillableWorkModalDefinitionField';
import { FormModel } from '../FormModel';
import type { SubjobBillingCodes, SubjobWorkOrders } from '..';

import styles from './styles.module.scss';

interface BillingCodeDropdownOption {
	id: number;
	customerId: string;
	unitPrice: Nullable<string>;
	unit: Nullable<string>;
	description: string;
	group: Nullable<string>;
}

type BillingCodeDropdownOptionWithUnitData = BillingCodeDropdownOption & {
	formUnit: Nullable<string>;
	formQuantity: Nullable<number>;
};

interface QuantityDropdownOption {
	id: QuantityUnitType;
	label: string;
}

const getFormData = getFormValues(FORMS.WORK_SUMMARY_BILLABLE_WORK);

const _delimitersRegEx = new RegExp('\/|-| ');

const _filterBillingCodes = (_option: BillingCodeVM, _searchText: string) => {
	_searchText.trim();
	const splitSearch = _searchText.toLowerCase().split(_delimitersRegEx).join('');
	const _customerIdToLowerCase = _option.customerId.toLowerCase().split(_delimitersRegEx).join('');
	const _descriptionToLowerCase = _option.description.toLowerCase().split(_delimitersRegEx).join('');

	if (!!splitSearch && _customerIdToLowerCase.includes(splitSearch) || _descriptionToLowerCase.includes(splitSearch)) {
		return true;
	}

	return false;
};

const _renderBillingCodeMenuItem = (_bc: BillingCodeDropdownOptionWithUnitData) => {
	const convertedUnit = _bc.formQuantity && _bc.formUnit && _bc.unit
		? convertUnits(_bc.formQuantity, QuantityUnitMap[_bc.formUnit], QuantityUnitMap[_bc.unit])
		: null;
	const convertedUnitLabel = convertedUnit && `${_bc.formQuantity} ${_bc.formUnit} = ${parseFloat(Number(convertedUnit).toFixed(4)).toString()} ${_bc.unit?.replace('_', '/')}`;
	const perUnitPriceLabel = `$ ${_bc.unitPrice} per ${_bc.unit?.replace('_', '/')} ${_bc.group ?? ''}`;

	return (
		<div className={styles['add-billable-work-modal__billing-code-dropdown-item']}>
			<b>{_bc.customerId}</b>
			<span><b>{perUnitPriceLabel}</b></span>
			{convertedUnit && (_bc.unit !== _bc.formUnit)
				? <p className={styles.dropdown_card_subtitle}>{convertedUnitLabel}</p>
				: ''
			}
			<p>{_bc.description}</p>
		</div>
	);
};

const _renderSubjobMenuItem = (_subjob: SubjobVM) => {
	return (
		<div className={styles['add-billable-work-modal__billing-code-dropdown-item']}>
			<b>{_subjob.jobCode}</b>
		</div>
	);
};

const _renderSelectedDropdownMenuItem = (_billingCode: BillingCodeDropdownOption) => <span key={_billingCode.id}>{_billingCode.customerId}</span>;

const _renderSelectedSubjob = (_subjob: SubjobVM) => <span key={_subjob.id}>{_subjob.jobCode}</span>;

const _renderNoBillingCodeMenuItem = (item) => <span>{item.label}</span>;

const quantityUnitOptions: QuantityDropdownOption[] = Object.keys(QuantityUnitLabel)
	.map((_unit: QuantityUnitType) => ({ id: _unit, label: QuantityUnitLabel[_unit] }));

type OwnProps = {
	showModal: boolean;
	closeModal: () => void;
	billingCodes: BillingCodeVM[];
	workOrders: JobWorkSummaryWorkOrderVM[];
	jobId: number;
	refreshTable: () => void;
	currentlyEditedRow: Nullable<JobWorkSummaryVM>;
	isProject: boolean;
	subjobs: SubjobVM[];
	subjobBillingCodes: SubjobBillingCodes[];
	subjobWorkOrders: SubjobWorkOrders[];
};

type Props = OwnProps & InjectedFormProps<FormModel> & ConnectedProps<typeof connector>;

const AddBillableWorkModal: React.FC<Props> = (props) => {
	const {
		closeModal,
		showModal,
		billingCodes,
		workOrders,
		initialize,
		destroy,
		change,
		formDataUnit,
		formDataBillingCodeId,
		formDataQuantity,
		formDataRevenue,
		formDataUnitPrice,
		formDataCustomerId,
		formDataGroup,
		valid,
		createJobWorkSummary,
		handleSubmit,
		jobId,
		refreshTable,
		currentlyEditedRow,
		editJobWorkSummary,
		dirty,
		isProject,
		subjobs,
		subjobBillingCodes,
		subjobWorkOrders,
		formWorkRequestId,
	} = props;

	const billingCodeOptions = React.useMemo(
		() => {
			const _billingCodes = !!subjobBillingCodes.length
				? subjobBillingCodes.find((_sjbc) => _sjbc.workRequestId === (formWorkRequestId ?? jobId))?.billingCodes ?? []
				: billingCodes;

			return !!formDataUnit ?
				_billingCodes.reduce((_acc: BillingCodeDropdownOption[], _bc: BillingCodeVM) => {
					const _bcUnit = QuantityUnitMap[_bc.unit];
					const _formUnit = QuantityUnitMap[formDataUnit.replace('/', '_')];
					if (_bcUnit && _formUnit && (
						(UnitClasses[_formUnit] && (UnitClasses[_bcUnit] === UnitClasses[_formUnit])) // if there is a conversion rate
						|| (_formUnit === _bcUnit) // if units are the same
					)) {
						_acc.push({
							customerId: _bc.customerId,
							id: _bc.id,
							description: _bc.description,
							unitPrice: _bc.unitPrice,
							group: _bc.group,
							unit: QuantityUnitMap[_bc.unit] ?? null,
						});
					}
					return _acc;
				}, [])
				: _billingCodes;
		}, [billingCodes, formDataUnit, formWorkRequestId, jobId, subjobBillingCodes]
	);

	const selectedBillingCode = React.useMemo(() =>
		billingCodeOptions.find((_bco) => _bco.id === formDataBillingCodeId),
		[billingCodeOptions, formDataBillingCodeId]
	);

	const filteredQuantityUnitOptions = React.useMemo(
		() => {
			const selectedUnit = selectedBillingCode?.unit;
			if (!selectedUnit) {
				return quantityUnitOptions;
			}
			return quantityUnitOptions.filter((unit) => {
				return (UnitClasses[unit.id] && (UnitClasses[unit.id] === UnitClasses[selectedUnit])) || (unit.id === selectedUnit);
			});
		}, [selectedBillingCode]
	);

	const workOrderOptions = React.useMemo(() => {
		const _workOrders = !!subjobWorkOrders.length
			? subjobWorkOrders.find((_sjwo) => _sjwo.workRequestId === (formWorkRequestId ?? jobId))?.workOrders ?? []
			: workOrders;

		const workOrderList = [..._workOrders];

		if (!!currentlyEditedRow?.workOrderId) {
			let originalWorkOrder: Nullable<JobWorkSummaryWorkOrderVM> = null;
			for (const sjWos of subjobWorkOrders) {
				originalWorkOrder = sjWos.workOrders.find((_wo) => _wo.id === currentlyEditedRow.workOrderId) ?? null;
				if (originalWorkOrder && workOrderList.every((_wo) => _wo.id !== originalWorkOrder!.id)) {
					workOrderList.unshift(originalWorkOrder);
					break;
				}
			}
		}

		return workOrderList.map((_wo) => ({ id: _wo.id, label: _wo.workOrderCode }));
	}, [formWorkRequestId, jobId, subjobWorkOrders, workOrders, currentlyEditedRow?.workOrderId]);

	const convertedFormUnitPrice = React.useMemo(() => {
		if (!selectedBillingCode ?? (selectedBillingCode?.unitPrice === undefined || selectedBillingCode?.unitPrice === null)) {
			return null;
		}
		const newUnitPrice = formDataUnit && selectedBillingCode?.unit
			? convertUnits(+selectedBillingCode.unitPrice, QuantityUnitMap[formDataUnit], QuantityUnitMap[selectedBillingCode.unit])
			: selectedBillingCode.unitPrice;

		return newUnitPrice ? +newUnitPrice : +selectedBillingCode.unitPrice;
	}, [selectedBillingCode, formDataUnit]);

	const onBillingCodeChange = React.useCallback((_billingCode: BillingCodeDropdownOption) => {
		const convertedQuantity = formDataUnit && _billingCode.unit
			? convertUnits(formDataQuantity ?? 0, QuantityUnitMap[formDataUnit], QuantityUnitMap[_billingCode.unit])
			: formDataQuantity;

		change('billingCodeId', _billingCode.id);
		change('billingCodeUnit', _billingCode.unit);
		change('customerId', _billingCode.customerId);
		change('group', _billingCode.group);
		change('unitPrice', _billingCode.unitPrice);
		change('description', _billingCode.description);
		change('revenue', +(_billingCode.unitPrice ?? 0) * (convertedQuantity ?? formDataQuantity ?? 0));
		if (!formDataUnit && _billingCode.unit) {
			change('unit', QuantityUnitMap[_billingCode.unit]);
		}
	}, [formDataQuantity, formDataUnit, change]);

	const onQuantityChange = React.useCallback((quantity: number) => {
		const convertedQuantity = formDataUnit && selectedBillingCode?.unit
			? convertUnits(+quantity ?? 0, QuantityUnitMap[formDataUnit], QuantityUnitMap[selectedBillingCode.unit])
			: quantity;

		change('revenue', +(formDataUnitPrice ?? 0) * (convertedQuantity ?? +quantity ?? 0));
	}, [formDataUnitPrice, formDataUnit, selectedBillingCode, change]);

	const onUnitChange = React.useCallback((unit: QuantityDropdownOption) => {
		const convertedQuantity = convertUnits(formDataQuantity ?? 0, unit?.id, selectedBillingCode?.unit as QuantityUnitType);

		change('revenue', +(formDataUnitPrice ?? 0) * (convertedQuantity ?? formDataQuantity ?? 0));
	}, [formDataUnitPrice, formDataQuantity, selectedBillingCode, change]);

	const onBillingCodeClear = React.useCallback(() => {
		change('customerId', null);
		change('billingCodeId', null);
		change('billingCodeUnit', null);
		change('group', null);
		change('description', null);
		change('revenue', null);
		change('unitPrice', null);
	}, [change]);

	const onUnitClear = React.useCallback(() => {
		change('unit', null);
		change('revenue', +(formDataUnitPrice ?? 0) * (formDataQuantity ?? 0));
	}, [change, formDataUnitPrice, formDataQuantity]);

	const onWorkOrderClear = React.useCallback(() => {
		change('workOrderId', null);
	}, [change]);

	const renderBillingCodePlaceholder = React.useCallback(() =>
		<span className={styles['add-billable-work-modal__billing-code-placeholder']}>
			Add Billing Code to see
		</span>
		, []);

	const onJobChange = React.useCallback((_jobId: React.ChangeEvent<HTMLInputElement>) => {
		change('workRequestId', _jobId);
		// Clean billing code and work Order information
		onBillingCodeClear();
		onWorkOrderClear();
	}, [change, onBillingCodeClear, onWorkOrderClear]);

	const renderBillingCodeDropdown = React.useCallback(() => {
		if (!!billingCodeOptions?.length) {
			return (
				<Field
					component={Dropdown}
					filterable={true}
					filterBy={_filterBillingCodes}
					fixed={false}
					isClearable={true}
					label="Billing Code *"
					name="billingCodeId"
					onClear={onBillingCodeClear}
					onValueChange={onBillingCodeChange}
					options={billingCodeOptions ?? []}
					placeholder="Add Billing Code"
					renderMenuItem={_renderBillingCodeMenuItem}
					renderSelected={_renderSelectedDropdownMenuItem}
					valueKey="id"
					withCaret={true}
				/>
			);
		}

		return (
			<Field
				component={Dropdown}
				id="customerId"
				label="Billing Code *"
				name="customerId"
				options={[{ disabled: true, label: 'No billing codes found.' }]}
				placeholder="No billing codes found."
				renderMenuItem={_renderNoBillingCodeMenuItem}
				withCaret={true}
			/>
		);
	}, [billingCodeOptions, onBillingCodeClear, onBillingCodeChange]);

	const onSubmit = React.useCallback(async (form: FormModel) => {
		if (
			!form.billingCodeId
			|| !form.customerId
			|| !form.unit
			|| !form.unitPrice
			|| !form.description
			|| !form.quantity
			|| !form.work
			|| !form.type
		) {
			return;
		}

		const jobWorkSummaryRM: JobWorkSummaryRM = {
			id: !!currentlyEditedRow ? currentlyEditedRow.id : undefined,
			billingCodeId: form.billingCodeId,
			billingCodeUnit: form.billingCodeUnit,
			customerId: form.customerId,
			definitionField1Name: currentlyEditedRow?.definitionField1Name ?? null,
			definitionField1Value: form.definitionFields?.[0] ?? null,
			definitionField2Name: currentlyEditedRow?.definitionField2Name ?? null,
			definitionField2Value: form.definitionFields?.[1] ?? null,
			definitionField3Name: currentlyEditedRow?.definitionField3Name ?? null,
			definitionField3Value: form.definitionFields?.[2] ?? null,
			definitionField4Name: currentlyEditedRow?.definitionField4Name ?? null,
			definitionField4Value: form.definitionFields?.[3] ?? null,
			informationField1Value: [form.informationalFields?.[0] ?? null],
			informationField1Name: undefined,
			informationField2Value: [form.informationalFields?.[1] ?? null],
			informationField2Name: undefined,
			informationField3Value: [form.informationalFields?.[2] ?? null],
			informationField3Name: undefined,
			informationField4Value: [form.informationalFields?.[3] ?? null],
			informationField4Name: undefined,
			description: form.description,
			group: form.group,
			invoiceId: null,
			quantity: form.quantity,
			startDate: form.date,
			type: form.type,
			unit: form.unit,
			work: form.work,
			workOrderId: form.workOrderId,
			fieldReportId: form.fieldReportId,
			unitPrice: form.unitPrice,
			comment: form.comment,
			workRequestId: form.workRequestId ?? jobId,
			revenue: form.revenue,
		};

		if (currentlyEditedRow) {
			await editJobWorkSummary(jobWorkSummaryRM);
		} else {
			await createJobWorkSummary(jobWorkSummaryRM);
		}
		closeModal();
		refreshTable();
	}, [currentlyEditedRow, closeModal, refreshTable, editJobWorkSummary, jobId, createJobWorkSummary]);

	const currentSubjob = React.useMemo(() => subjobs.find((sj) => sj.id === jobId), [jobId, subjobs]);

	React.useEffect(() => {
		if (showModal) {
			if (currentlyEditedRow) {
				initialize(new FormModel(currentlyEditedRow));
			} else {
				const fm = new FormModel();
				fm.workRequestId = jobId;
				initialize(fm);
			}
		} else {
			destroy();
		}
	}, [currentlyEditedRow, destroy, initialize, jobId, showModal]);

	const showRevenue = !!formDataCustomerId;
	const titleAndSubmitName = !!currentlyEditedRow ? 'Edit Billable Work' : 'Add New Billable Work';

	const canSubmitEdited = dirty && valid;
	const canSubmit = !!currentlyEditedRow ? canSubmitEdited : valid;

	const calculatedUnitPriceValue = React.useMemo(() => {
		if (convertedFormUnitPrice === null || formDataUnit === null) {
			return renderBillingCodePlaceholder();
		} else {
			return <span>{`$${convertedFormUnitPrice.toFixed(4)} per ${formDataUnit.replace('_', '/')}`}</span>;
		}
	}, [convertedFormUnitPrice, formDataUnit, renderBillingCodePlaceholder]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="lg"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title={titleAndSubmitName}
			/>
			<CustomModal.Body>
				<div className={styles['add-billable-work-modal']}>
					<Field
						component={Dropdown}
						filterable={true}
						filterBy={['label']}
						fixed={false}
						hasBlankOption={true}
						id="workOrderId"
						label="Work Order"
						labelKey="label"
						name="workOrderId"
						onClear={onWorkOrderClear}
						options={workOrderOptions}
						placeholder="Select Work Order"
						valueKey="id"
						withCaret={true}
					/>

					<div className={styles['add-billable-work-modal__date']}>
						<Field
							component={DateInput}
							dateFormat={TimeFormatEnum.DB_DATE_ONLY}
							id="date"
							label="Date *"
							name="date"
							originalDateFormat={TimeFormatEnum.DB_DATE_ONLY}
							placeholderText="Select Date"
							showTimeSelect={true}
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<Field
							component={Input}
							fixed={true}
							id="work"
							label="Work *"
							name="work"
							placeholder="Add Work"
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<Field
							component={Input}
							fixed={true}
							id="type"
							label="Type *"
							name="type"
							placeholder="Add Type"
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<FieldArray<void>
							component={AddBillableWorkModalDefinitionField}
							name="definitionFields"
						/>
					</div>
					<div className={styles['add-billable-work-modal__multiple']}>
						<FieldArray<void>
							component={AddBillableWorkModalInformationalField}
							name="informationalFields"
						/>
					</div>

					<Field
						component={Input}
						fixed={true}
						label="Quantity *"
						name="quantity"
						onValueChange={onQuantityChange}
						placeholder="Add Quantity"
						type="number"
					/>
					<Field
						component={Dropdown}
						defaultValue={undefined}
						disabled={false}
						filterable={true}
						filterBy={['id', 'label']}
						fixed={true}
						id="unit"
						label="Unit *"
						labelKey="label"
						name="unit"
						onClear={onUnitClear}
						onValueChange={onUnitChange}
						options={filteredQuantityUnitOptions}
						placeholder="Add Unit"
						valueKey="id"
						withCaret={true}
					/>
					<div>
						{
							isProject &&
							process.env.FTD_PROJECT !== 'true' &&
							<Field
								component={Dropdown}
								defaultValue={currentSubjob}
								fixed={true}
								isClearable={true}
								label="Sub-job"
								name="workRequestId"
								onChange={onJobChange}
								options={subjobs}
								renderMenuItem={_renderSubjobMenuItem}
								renderSelected={_renderSelectedSubjob}
								valueKey="id"
								withCaret={true}
							/>
						}
						{renderBillingCodeDropdown()}
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							Group
							<Field
								component={Text}
								defaultValue={formDataCustomerId && !formDataGroup ? '-' : renderBillingCodePlaceholder()}
								id="group"
								name="group"
							/>
						</div>
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							Description
							<Field
								component={Text}
								defaultValue={renderBillingCodePlaceholder()}
								id="description"
								name="description"
							/>
						</div>
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							<div>Unit price</div>
							<b>{calculatedUnitPriceValue}</b>
						</div>
						<div className={styles['add-billable-work-modal__billing-code-item']}>
							<div>
								Revenue
							</div>
							<b>
								{showRevenue
									? dollarFormatter.format(formDataRevenue ?? 0)
									: renderBillingCodePlaceholder()
								}
							</b>
						</div>
					</div>
					<div className={styles['add-billable-work-modal__comment']}>
						<Field
							className={styles['add-billable-work-modal']}
							component={Textarea}
							id="comment"
							label="Comment"
							maxCharacters={750}
							name="comment"
							placeholder="Add Comment"
							rows={16}
							showMaxCharactersLabel={true}
						/>
					</div>
				</div>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Cancel
				</Button>
				<SubmitButton
					disabled={!canSubmit}
					label={titleAndSubmitName}
					onClick={handleSubmit(onSubmit)}
					variant="primary"
				/>
			</CustomModal.Footer>
		</CustomModal>
	);
};

function mapStateToProps(state: RootState) {
	const {
		unit: formDataUnit,
		quantity: formDataQuantity,
		billingCodeId: formDataBillingCodeId,
		revenue: formDataRevenue,
		unitPrice: formDataUnitPrice,
		customerId: formDataCustomerId,
		group: formDataGroup,
		workRequestId: formWorkRequestId,
		workOrderId: formWorkOrderId,
	} = (getFormData(state) as FormModel) ?? ({} as Partial<FormModel>);

	return {
		formDataUnit: formDataUnit ? formDataUnit.replace('/', '_') : null,
		formDataBillingCodeId,
		formDataQuantity,
		formDataRevenue,
		formDataUnitPrice,
		formDataCustomerId,
		formDataGroup,
		formWorkRequestId,
		formWorkOrderId,
	};
}

const formConnector = reduxForm<FormModel>({
	form: FORMS.WORK_SUMMARY_BILLABLE_WORK,
	validate: FormModel.validate,
});

function mapDispatchToProps() {
	return {
		createJobWorkSummary: JobActions.createJobWorkSummary,
		editJobWorkSummary: JobActions.editJobWorkSummary,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	formConnector,
	connector
);

export default enhance(AddBillableWorkModal);
